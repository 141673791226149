import { defineCustomElement } from 'vue'
import Auth from './Auth.vue';
import CheckBoxField from './customerElements/CheckBoxField.vue';
import AuthProvider from './AuthProvider';
import PasskeyAssociate from './PasskeyAssociate.vue';
import PasskeyAssociateLogin from './PasskeyAssociateLogin.vue';
import LogoutHandler from "./LogoutHandler";
import PassKeyList from "./PassKeyList";
import PasskeyAppend from "./PasskeyAppend";

/** Returns the styles of a Vue component including all of its child components'. */
function deepStylesOf({ styles = [], components = {} }) {
    const unique = array => [...new Set(array)];
    return unique([...styles, ...Object.values(components).flatMap(deepStylesOf)]);
}

function isDefinedCustomElement(name) {
    return window !== undefined && window.customElements !== undefined && window.customElements.get(name) !== undefined;
}

const elements = [
    {
        element: Auth,
        name: 'corbado-auth',
    },
    {
        element: CheckBoxField,
        name: 'corbado-checkbox',
    },
    {
        element: AuthProvider,
        name: 'corbado-auth-provider',
    },
    {
        element: LogoutHandler,
        name: 'corbado-logout-handler',
    },
    {
        element: PasskeyAssociate,
        name: 'corbado-passkey-associate',
    },
    {
        element: PasskeyAssociateLogin,
        name: 'corbado-passkey-associate-login',
    },
    {
        element: PassKeyList,
        name: 'corbado-passkey-list',
    },
    {
        element: PasskeyAppend,
        name: 'corbado-passkey-append',
    },
]

elements.forEach(e => {
    if (isDefinedCustomElement(e.name)) {
        return
    }

    /** <Workaround>
     *
     * Unfortunately it's error prone and results in poor dev experience:
     * - load everything as CE
     * - recursively add child element styles to the root element
     * - [if TypeScript is set to strict, sort out the "styles" prop being set to any] */

    e.element.styles = deepStylesOf(e.element);

    const customElement = defineCustomElement(e.element)
    customElements.define(e.name, customElement)
})