import IntegrationError from "./IntegrationError";

export default class IntegrationErrorClient extends IntegrationError {

    constructor(message, link = '', forceShowError = false) {
        super(message);
        this._link = link;
        this._forceShowError = forceShowError;
        this._type = 'client'
    }

    get link() {
        return this._link;
    }

    get forceShowError() {
        return this._forceShowError;
    }
}