import IntegrationErrorClient from "../errors/IntegrationErrorClient";

export default function (associationToken) {
    if (associationToken === undefined) {
        throw new IntegrationErrorClient('Missing attribute "association-token"', 'https://docs.corbado.com/web-app-integrations/web-component', true)
    }

    if (!associationToken.startsWith('ast1_')) {
        throw new IntegrationErrorClient('Invalid attribute "association-token": It must start with "ast1_"', 'https://docs.corbado.com/web-app-integrations/web-component', true)
    }

    if (associationToken.length !== 35) {
        throw new IntegrationErrorClient('Invalid attribute "association-token": The length must be exactly 35 chars', 'https://docs.corbado.com/web-app-integrations/web-component', true)
    }
}