<template>
  <div
      id="corbado-passkey-associate"
      style="
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    "
  >
    <error-handler>
      <div style="margin-top: 1rem">
        <styled-btn
          type="primary"
          dataAction="sign_up"
          :fullWidth="true"
          @click="associateHandler"
          :isLoading="associateLoading"
        >
          Create passkey
        </styled-btn>
      </div>
    </error-handler>
  </div>
</template>

<script>
import {provide, ref} from "vue";
import StyledBtn from "./elements/StyledBtn.vue";
import Logger from "./helper/Logger";
import validateProjectID from "./helper/ValidateProjectID";
import WebauthnService from "./services/WebauthnService";
import validateEndpoint from "./helper/ValidateEndpoint";
import validateAssociateToken from "./helper/ValidateAssociateToken";
import WCAxios from "./services/WCAxios";
import ErrorHandler from "./ErrorHandler";
import IntegrationErrorClient from "./errors/IntegrationErrorClient";
import Layout from "./Layout";
import ErrorStateHandler from "./errors/ErrorStateHandler";

const PASSKEY_CREATION_SUCCESSFUL = "PASSKEY_CREATION_SUCCESSFUL"
const PASSKEY_CREATION_FAILED = "PASSKEY_CREATION_FAILED"
const DEVICE_NOT_PASSKEY_READY = "DEVICE_NOT_PASSKEY_READY"

export default {
  name: "PasskeyAssociate",
  components: {
    ErrorHandler,
    StyledBtn,
    Layout,
  },
  props: {
    'project-id': {
      type: String,
      required: true,
    },
    endpoint: {
      default: '',
      type: String,
    },
    associationToken: {
      type: String,
      required: true,
    }
  },

  setup(props, context) {
    const associateLoading = ref(false);
    const errorHandler = ref(new ErrorStateHandler())
    provide('errorHandler', errorHandler)
    const webauthnSvc = new WebauthnService(errorHandler);

    try {
      validateProjectID(props.projectId)
      validateAssociateToken(props.associationToken)

      const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'
      validateEndpoint(endpoint)

      WCAxios.configure(wcInstance => {
        wcInstance.defaults.baseURL = endpoint
        wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = props.projectId
        return wcInstance
      })

    } catch (err) {
      errorHandler.value.error ={
        message: err.message,
        links: err.type === 'client' ? [err.link] : '',
        errorSide: err.type === 'client' ? 'client' : 'server',
        forceShowError: err.type === 'client' ? err.forceShowError : false,
      }

      return {
      }
    }

    const associateHandler = (e) => {
      e.preventDefault();
      associateLoading.value = true;

      Logger.debug("Initiate webauthn signup");

      webauthnSvc.CheckDevicePasskeyReadiness().then((ready) => {
        if (ready) {
          webauthnSvc
              .AssociateStart(props.associationToken)
              .then((redirectURL) => {
                Logger.debug("Performed webauthn signup", { redirectURL });
                associateLoading.value = false;


                context.emit(PASSKEY_CREATION_SUCCESSFUL);
              })
              .catch((err) => {
                associateLoading.value = false;
                if (err === null) {
                  return;
                }

                Logger.error('Associate register failed', err)

                context.emit(PASSKEY_CREATION_FAILED, {data: {err}});
              });
        } else {
          associateLoading.value = false;

          Logger.error("Device not ready for webauthn signup");

          context.emit(DEVICE_NOT_PASSKEY_READY);
        }
      })
    };

    return {
      associateHandler,
      associateLoading,
    };
  },
};
</script>

<style scoped>
  svg {
    margin-top: 0.3rem;
  }
  * {
    color: #5D6785;
  }
  #corbado-passkey-associate {
     max-width: 384px;
     box-sizing: border-box;
     box-shadow: var(--box-shadow, none);
     margin-top: var(--margin-top, inherit);
   }
</style>
