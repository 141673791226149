import HandleAxiosError from "../helper/HandleAxiosError";
import WCAxios from "./WCAxios";

class MeService {

    constructor(errorHandler) {
        this._errorHandler = errorHandler
    }

    ListPassKeys(sessionToken) {
        return HandleAxiosError(WCAxios.get().get('/v1/me/passkeys', {}, sessionToken !== '' && sessionToken !== undefined ? {
            headers: { Authorization: `Bearer ${sessionToken}` }} : {},
        ), this._errorHandler).then(rsp => {
            return { passkeys: rsp.data.data.passkeys, paging: rsp.data.data.paging }
        })
    }

}

export default MeService