import HandleAxiosError from "../helper/HandleAxiosError";
import WCAxios from "./WCAxios";
import {shortCookie} from "../helper/CookieHelper";
import {setLongSession} from "../helper/LocalStorageHelper";

export default class PasswordService {

    constructor(errorHandler) {
        this._errorHandler = errorHandler
    }

    SignIn(username, password) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/password/verify', {username, password}), this._errorHandler).then(rsp => {
            shortCookie(rsp.data.data)
            setLongSession(rsp.data.data.longSession)

            return {sessionToken: rsp.data.data.sessionToken, redirectURL: rsp.data.data.redirectURL}
        }).catch(err => {
            throw err
        })
    }

}