import { create, get } from "@github/webauthn-json";
import { shortCookie } from "../helper/CookieHelper";
import HandleAxiosError from "../helper/HandleAxiosError";
import { setLongSession } from "../helper/LocalStorageHelper";
import Logger from "../helper/Logger";
import WCAxios from "./WCAxios";

let mediationController = null

class WebauthnService {

    constructor(errorHandler) {
        this._errorHandler = errorHandler
    }

    SignIn(username) {
        this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/login/start', {
            username
        }), this._errorHandler).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})

            const challenge = JSON.parse(rsp.data.data.challenge)

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        }).catch(this._handleNavigatorError)
    }

    SignUp(username, name) {
        this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/register/start', {
            username: username,
            fullName: name,
        }), this._errorHandler).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/register/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        }).catch(this._handleNavigatorError)
    }

    Append(sessionToken) {

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/append/start', {}, sessionToken !== '' && sessionToken !== undefined ? {
            headers: { Authorization: `Bearer ${sessionToken}` }} : {},
        ), this._errorHandler).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/append/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        }).catch(this._handleNavigatorError)
    }

    _handleNavigatorError(err) {
        if (err.name === 'DomException' || err.name === 'SecurityError') {

            console.error(err)

            this._errorHandler.value.error = {
                message: 'Relying Party ID and Browser URL not matching. Please check the Corbado developer panel',
                errorLink: 'https://app.corbado.com/app/settings/general/urls',
            }

            throw null
        }

        throw err
    }

    MediationStart(username) {
        this.AbortMediation()
        const controller = new AbortController()
        const signal = controller.signal
        mediationController = controller

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/mediation/start', {
            username,
        }), this._errorHandler).then(rsp => {
            Logger.debug('Webauthn challenge', {challenge: rsp.data.data.challenge})

            const challenge = JSON.parse(rsp.data.data.challenge)
            challenge.mediation = "conditional"
            challenge.signal = signal

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    return {redirectURL: rsp.data.data.redirectURL, username: rsp.data.data.username, confirmedCredential: rsp.data.data.confirmedCredential}
                });
            })
        }).catch(this._handleNavigatorError)
    }

    AbortMediation() {
        if (mediationController) {
            try {
                mediationController.abort('User chose different login')
            } catch (e) {
                Logger.error('Mediation failed', e)
            }

            mediationController = null
        }

    }

    AssociateStart(associationToken) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/associate/start', {
            associationToken
        }), this._errorHandler).then(rsp => {

            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/append/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                )
            })
        }).catch(this._handleNavigatorError)
    }

    AssociateLogin(username){
        // TODO add conditional ui
        //this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/login/start', {
            username
        }), this._errorHandler).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        }).catch(this._handleNavigatorError)
    }

     async DeletePasskey(credentialID) {
        const rsp = await WCAxios.get().delete(
             `/v1/me/passkeys/${credentialID}`
         );
         return rsp;
    }

    CheckDevicePasskeyReadiness() {
        if (window.PublicKeyCredential) {
            return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        } else {
            return Promise.resolve(false)
        }
    }
}

export default WebauthnService