<template>
  <div class="dialog" v-if="showDialog">
    <div class="dialog-content">
      <div class="dialog-header">You already have a passkey on this device</div>
      <div class="dialog-body">Everything is done on this device.</div>
      <div class="dialog-footer">
        <styled-btn
            class="btn-outline"
            @click="showDialog = false"
            style="padding: 0.6rem 3.6rem"
        >Ok
        </styled-btn>
      </div>
    </div>
  </div>

  <div
    id="corbado-passkey-append"
    v-if="authed"
  >
    <error-handler>
      <div style="margin-top: 1rem">
        <styled-btn
          type="primary"
          dataAction="sign_up"
          :fullWidth="true"
          @click="appendHandler"
          :isLoading="appendLoading"
        >
          Create passkey
        </styled-btn>
      </div>
    </error-handler>
  </div>
</template>

<script>
import {provide, ref} from "vue";
import StyledBtn from "./elements/StyledBtn.vue";
import Logger from "./helper/Logger";
import validateProjectID from "./helper/ValidateProjectID";
import WebauthnService from "./services/WebauthnService";
import validateEndpoint from "./helper/ValidateEndpoint";
import WCAxios from "./services/WCAxios";
import ErrorHandler from "./ErrorHandler";
import Layout from "./Layout";
import ErrorStateHandler from "./errors/ErrorStateHandler";
import SessionService from "./session/SessionService";

const PASSKEY_CREATION_SUCCESSFUL = "PASSKEY_CREATION_SUCCESSFUL"
const PASSKEY_CREATION_FAILED = "PASSKEY_CREATION_FAILED"
const DEVICE_NOT_PASSKEY_READY = "DEVICE_NOT_PASSKEY_READY"

export default {
  name: "PasskeyAppend",
  components: {
    ErrorHandler,
    StyledBtn,
    Layout,
  },
  props: {
    'project-id': {
      type: String,
      required: true,
    },
    endpoint: {
      default: '',
      type: String,
    },
  },

  setup(props, context) {
    const appendLoading = ref(false);
    const errorHandler = ref(new ErrorStateHandler())
    provide('errorHandler', errorHandler)
    const webauthnSvc = new WebauthnService(errorHandler);
    const authed = ref(false)
    const showDialog = ref(false)

    try {
      validateProjectID(props.projectId)

      const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'
      validateEndpoint(endpoint)

      WCAxios.configure(wcInstance => {
        wcInstance.defaults.baseURL = endpoint
        wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = props.projectId
        return wcInstance
      })

      const session = new SessionService(props.projectId, endpoint)
      session.isAuthenticated().then(state => {
        authed.value = state
      })

    } catch (err) {
      errorHandler.value.error ={
        message: err.message,
        links: err.type === 'client' ? [err.link] : '',
        errorSide: err.type === 'client' ? 'client' : 'server',
        forceShowError: err.type === 'client' ? err.forceShowError : false,
      }

      return {
      }
    }

    const appendHandler = (e) => {
      e.preventDefault();
      appendLoading.value = true;
      showDialog.value = false

      Logger.debug("Initiate webauthn signup");

      webauthnSvc.CheckDevicePasskeyReadiness().then((ready) => {
        if (ready) {
          webauthnSvc
              .Append('')
              .then((redirectURL) => {
                Logger.debug("Performed webauthn signup", { redirectURL });
                appendLoading.value = false;


                context.emit(PASSKEY_CREATION_SUCCESSFUL);
              })
              .catch((err) => {
                appendLoading.value = false;
                if (err === null) {
                  return;
                }

                showDialog.value = true

                context.emit(PASSKEY_CREATION_FAILED, {data: {err}});
              });
        } else {
          appendLoading.value = false;

          Logger.error("Device not ready for webauthn signup");

          context.emit(DEVICE_NOT_PASSKEY_READY);
        }
      })
    };

    return {
      appendHandler,
      appendLoading,
      authed,
      showDialog,
    };
  },
};
</script>

<style scoped>

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  padding: 20px;
  background: #2A334C;
  border-radius: 10px;
}

.dialog-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.dialog-body {
  margin-bottom: 20px;
  color: white;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
