<template>

  <input-field-container
    :error-message="errorMessage"
    :label="label"
    :has-content="hasContent"
    :has-focus="hasFocus"
  ><input
        :type="type"
        :name="name"
        :data-input="name"
        :v-model="value"
        :value="value"
        @input="$emit('update:modelValue', $event.target.value)"
        :autocomplete="autocomplete"
        :disabled="disabledValue"
        @focusin="focusIn"
        @focusout="focusOut"
    />
  </input-field-container>

</template>

<script>
import ErrorField from "./ErrorField.vue";
import InputFieldContainer from "./InputFieldContainer";
import {ref} from "vue";
import store from "../store";
export default {
  name: 'InputField',
  components: {InputFieldContainer, ErrorField},
  props: {
    type: String,
    name: {
      type: String,
      default: 'username',
      validate: (value) => {
        return ['name', 'username', 'password'].includes(value)
      }
    },
    autocomplete: String,
    label: String,
    errorMessage: String,
    value: String,
    disabled: {
      default: false,
      type: Boolean
    },
  },
  setup() {
    const hasFocus = ref(false)

    const focusIn = () => {
      hasFocus.value = true
    }

    const focusOut = () => {
      hasFocus.value = false
    }

    return {
      hasFocus,
      focusIn,
      focusOut,
    }

  },
  computed: {
    hasContent() {
      return !(this.value === undefined || this.value === '') || store.state.webComponent.disableInputAnimation
    },
    disabledValue() {
      return this.disabled ? "disabled" : null
    }
  }
}
</script>