import WebWorker from "./timer/WebWorker";
import Timeout from "./timer/Timeout";

export default class TimerWrapper {

    #timer

    constructor() {
        if (window.Worker) {
            this.#timer = new WebWorker()
        } else {
            this.#timer = new Timeout()
        }
    }

    timeout(ms) {
        return this.#timer.timeout(ms)
    }

    interval(ms, callback) {
        return this.#timer.interval(ms, callback)
    }

    clearInterval(id) {
        return this.#timer.clearInterval(id)
    }

}