import UserService from "../services/UserService";
import WebauthnService from "../services/WebauthnService";
import Logger from "../helper/Logger";

export default {
    state() {
        return {
            username: '',
            userFullName: '',
            userFieldFocus: false,
            loginMethods: [],
            passwordLoginEndpoint: "",
            sessionToken: "",
            redirectURL: "",
            currentLoginMethod: "",
            debug: false,
        }
    },
    mutations: {
        setUsername(state, username) {
            localStorage.setItem('username', username)
            const userInput = document.getElementById('corbado-username')
            if (userInput !== null) {
                userInput.value = username
            }

            state.username = username
            state.loginMethods = []
        },
        setUserFullName(state, userFullName) {
            state.userFullName = userFullName
        },
        setUserFieldFocus(state, focus) {
          state.userFieldFocus = focus
        },
        setLoginMethods(state, methods) {
            state.loginMethods = methods
        },
        setPasswordLoginEndpoint(state, endpoint) {
            state.passwordLoginEndpoint = endpoint
        },
        setCurrentLoginMethod(state, value) {
            state.currentLoginMethod = value
        },
        setSessionToken(state, sessionToken) {
            state.sessionToken = sessionToken
        },
        setRedirectURL(state, redirectURL) {
            state.redirectURL = redirectURL
        },
    },
    getters: {
        renderingType: state => state.renderingType,
    },
    actions: {

        async fetchLoginMethods({ commit }, {username, errorHandler}) {

            const u = new UserService(errorHandler)
            return u.AuthMethods(username).then(rsp => {
                commit('setLoginMethods', rsp.selectedMethods)
                commit('setPasswordLoginEndpoint', rsp.passwordLoginEndpoint)

                return rsp
            })
        },

        async processWebauthnMediation({state, commit}, {errorHandler}) {

            if (!PublicKeyCredential.isConditionalMediationAvailable ||
                !await PublicKeyCredential.isConditionalMediationAvailable()) {
                Logger.debug('Conditional ui not possible')
                return
            }

            const iOSVersion = parseFloat(
                ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1])
                    .replace('undefined', '3_2').replace('_', '.').replace('_', '')
            );

            if (navigator.userAgent.indexOf('Edg/') > -1 || (navigator.userAgent.indexOf('iPhone') > -1 && iOSVersion < 16.4)) {
                Logger.debug('Conditional ui for iphone iOS < 16.4 and edge not possible', {userAgent: navigator.userAgent})
                return;
            }

            Logger.debug('Start conditional ui')

            const webauthnSvc = new WebauthnService(errorHandler)

            return webauthnSvc.MediationStart(state.username).then(({ confirmedCredential, username, redirectURL }) => {
                commit('setUsername', username)

                if (!confirmedCredential) {
                    throw new Error('authentication_method_pending')
                }

                window.location = redirectURL
            })
        },
    }
}