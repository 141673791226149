import IntegrationErrorClient from "../errors/IntegrationErrorClient";
import AttributeErrorFactory from "./AttributeErrorFactory";

export default function (projectID) {
    if (projectID === undefined) {
        AttributeErrorFactory('project-id', 'Is missing', true)
    }

    if (!projectID.match(/^pro\-\d+$/)) {
        AttributeErrorFactory('project-id', 'It must start with "pro-" followed by number (e.g. pro-123456789)', true)
    }
}