import workerCode from '../webworker/workerTimers.worker.js'

export default class WebWorker {

    #worker
    #callbacks

    constructor() {
        // Create a Blob with the worker code
        const blob = new Blob([workerCode], { type: "application/javascript" });

        // Create an object URL from the Blob
        const workerUrl = URL.createObjectURL(blob);

        this.#worker = new Worker(workerUrl, { name: 'wc-timer' });
        this.#worker.onmessage = e => {
            this.#handleMessage(e)
        }
        this.#callbacks = {}
    }

    #handleMessage(e) {
        const id = e.data.id
        this.#callbacks[id]()
    }

    async timeout(ms) {
        const id = this.#generateId()

        return new Promise((resolve, reject) => {
            try {
                this.#callbacks[id] = () => {
                    this.#callbacks[id] = undefined
                    resolve()
                }
                this.#worker.postMessage({
                    event: 'timeout',
                    payload: {
                        id,
                        ms,
                    }
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    #generateId() {
        return Math.random() *  Math.random()
    }

    async interval(ms, callback) {
        const id = this.#generateId()

        this.#callbacks[id] = () => {
            try {
                callback(id)
            } catch (e) {
                console.error(e)
            }

        }
        this.#worker.postMessage({
            event: 'interval',
            payload: {
                id,
                ms,
            }
        })

        return id
    }

    clearInterval(id) {
        this.#worker.postMessage({
            event: 'resetInterval',
            payload: {
                id,
            }
        })
    }

}