<template>

  <div>
    <input-field
      id="password"
      type="password"
      :label="i18n.$t('password')"
      name="password"
      autocomplete="password"
      v-model="password"
      :value="password"
      :errorMessage="errorMessage.password">
    </input-field>
    <error-field
        v-if="errorMessage.global !== undefined"
        :errorMessage="errorMessage.global"></error-field>

    <styled-btn
      dataAction="login"
      type="primary"
      :full-width="true"
      @click="handleLogin"
      :is-loading="loading"
    >{{ i18n.$t('login') }}</styled-btn>
  </div>

</template>
<script>
import InputField from "../elements/InputField";
import {inject, ref} from "vue";
import StyledBtn from "../elements/StyledBtn";
import ErrorField from "../elements/ErrorField";
import store from "../store";
import {Pages} from "../routing";
import PasswordService from "../services/PasswordService";
import {useI18n} from "../i18n/i18nPlugin";
import Logger from "../helper/Logger";

export default {
  components: {StyledBtn, InputField, ErrorField},
  name: "password-login",
  setup() {
    const i18n = useI18n();

    const password = ref('')
    const errorMessage = ref({})
    const loading = ref(false)
    const errorHandler = inject('errorHandler')
    const passwordSvc = new PasswordService(errorHandler)

    const handleLogin = e => {
      e.preventDefault()
      loading.value = true

      passwordSvc.SignIn(
          store.state.user.username,
          password.value,
      ).then(({sessionToken, redirectURL}) => {

        Logger.debug('Password sign in successful', {sessionToken, redirectURL})
        loading.value = false

        if (!store.state.browser.webauthnSupported || store.state.user.loginMethods.includes('webauthn')) {
          // @ts-ignore
          window.location = redirectURL
          return
        }

        store.commit('setSessionToken', sessionToken)
        store.commit('setRedirectURL', redirectURL)
        store.commit(
            "switchPage",
            Pages.PAGE_APPEND_PASSKEY
        )

      }).catch(err => {
        loading.value = false

        if (err !== null) {
          errorMessage.value = err
        }
      })
    }

    return {
      password,
      errorMessage,
      handleLogin,
      loading,
      i18n,
    }
  }
}
</script>