import Logger from "../helper/Logger";

/**
 * ErrorStateHandler
 *
 * This error state handler provides us the possibility to make use of a scoped error handling.
 * Imagine you have several web components on a web page and there occurs an error during an ajax call.
 * When using a store the error would be on each component instead of on the specific.
 * As this don't work out too good, we needed something scoped for the specific for each web component "instance"
 * So we make use of inject and provide of vue to have access in all subcomponents / the chain underneath.
 * This state handler hold the values for all fields and allows error handler to render a pretty content.
 */
class ErrorStateHandler {

    _filterUndefined = data => {
        const mapped = {}

        Object.keys(data).forEach(key => {
            if (data[key] !== undefined) {
                mapped[key] = data[key]
            }
        })

        return mapped
    }

    _errorMessage = ''
    _errorDetailLink = ''
    _errorLinks = ''
    _errorRequestLink = ''
    _errorRequestID = ''
    _errorSide = ''
    _errorType = ''
    _forceShowError = ''

    set error({message, detailLink, requestID, requestLink, links, errorSide, errorType, forceShowError}) {
        Logger.error(message, this._filterUndefined({detailLink, requestID, requestLink, links, errorSide, errorType}))

        this._errorMessage = message
        this._errorDetailLink = detailLink ?? ''
        this._errorRequestLink = requestLink ?? ''
        this._errorLinks = links ?? []
        this._errorRequestID = requestID ?? ''
        this._errorSide = errorSide ?? 'server'
        this._errorType = errorType ?? ''
        this._forceShowError = forceShowError ?? false
        console.log(this)
    }

    resetError() {
        this._errorMessage = ''
        this._errorDetailLink = ''
        this._errorRequestID = ''
        this._errorRequestLink = ''
        this._errorLinks = []
        this._errorSide = ''
        this._errorType = ''
        this._forceShowError = false
    }

    get errorMessage() {
        return this._errorMessage;
    }

    get errorDetailLink() {
        return this._errorDetailLink;
    }

    get errorLinks() {
        return this._errorLinks;
    }

    get errorRequestLink() {
        return this._errorRequestLink;
    }

    get errorRequestID() {
        return this._errorRequestID;
    }

    get errorSide() {
        return this._errorSide;
    }

    get errorType() {
        return this._errorType;
    }

    get forceShowError() {
        return this._forceShowError;
    }
}

export default ErrorStateHandler