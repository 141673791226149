<template>
  <p class="text-center">{{ message }}</p>
  <div class="mt-8">
    <styled-btn
      v-if="showResend"
      type="primary"
      :fullWidth="true"
      @click="resend"
      :isLoading="resendIsLoading"
    >{{ i18n.$t('resend_email') }}</styled-btn>
    <styled-btn
      v-if="showBack"
      type="secondary"
      :fullWidth="true"
      @click="back"
      :isLoading="false"
    >{{ i18n.$t('back') }}</styled-btn>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import EmailLinkService from "../services/EmailLinkService"
import store from "../store";
import {Pages} from "../routing";
import { useI18n } from '../i18n/i18nPlugin'
import StyledBtn from "../elements/StyledBtn";
import Logger from "../helper/Logger";

export default {
  components: {StyledBtn},
  setup() {
    const message = ref("")
    const errorHandler = inject('errorHandler')
    const emailLinkSvc = new EmailLinkService(errorHandler)
    const i18n = useI18n();

    const showBack = ref(false)
    const showResend = ref(false)
    const resendIsLoading = ref(false)

    const back = e => {
      e.preventDefault()

      store.commit('switchPage', Pages.PAGE_LOGIN)
      store.commit('setCurrentLoginMethod', '')
    }

    const resend = e => {
      e.preventDefault()

      resendIsLoading.value = true;
      emailLinkSvc.SignIn(store.state.user.username).then(emailLinkID => {
        resendIsLoading.value = false;
        Logger.debug('Send email link', {username: store.state.user.username, emailLinkID})
        store.commit('setEMailLinkIDPending', emailLinkID)

        store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
      }).catch(err => {
        resendIsLoading.value = false;
        Logger.error('Failed to resend email magic link', err)
      })

    }

    return {
      message,
      emailLinkSvc,
      i18n,
      back,
      showBack,
      showResend,
      resendIsLoading,
      resend,
    }
  },
  beforeMount() {
    const i18n = useI18n();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.corbadoEmailLinkID === undefined || params.corbadoToken === undefined) {
      this.message = i18n.$t('invalid_email_link')
    } else {
      this.message = i18n.$t('processing')

      this.emailLinkSvc.Confirm(
          params.corbadoEmailLinkID,
          params.corbadoToken,
      ).then(({redirectURL, sessionToken, authMethods}) => {

        // If webauthn is not supported we can follow redirect url
        // Same is if user can webauthn but wants to use email magic link
        if (!store.state.browser.webauthnSupported || authMethods.includes('webauthn')) {
          window.location = redirectURL
          return
        }

        if (store.state.projectConfig.showPasskeyAppend) {
          // In other cases we ask for adding passkey
          store.commit('setSessionToken', sessionToken)
          store.commit('setRedirectURL', redirectURL)
          store.commit('switchPage', Pages.PAGE_APPEND_PASSKEY)
        } else {
          window.location = redirectURL
        }

      }).catch (e => {
        if (e.response.data.httpStatusCode === 400) {
          // Allow retrigger process when we have users name
          // Otherwise show back button in order to start process again

          if (store.state.user.username !== '') {
            this.showBack = true
            this.showResend = true
          } else {
            this.showResend = false
            this.showBack = true
          }

          this.message = i18n.$t('email_link_expired')
        } else {
          this.message = 'Login failed with ' + e.message
        }

      })
    }
  }
}

</script>