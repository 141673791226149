import Logger from "../helper/Logger";

const calculatePasskeyAppendInterval = passkeyAppendInterval => {
    switch (passkeyAppendInterval) {
        case "0d":

            passkeyAppendInterval = 0
            break
        case "1d":

            passkeyAppendInterval = 1
            break
        case "3d":

            passkeyAppendInterval = 3
            break
        case "1w":

            passkeyAppendInterval = 7
            break
        case "3w":

            passkeyAppendInterval = 21
            break
        case "1m":

            passkeyAppendInterval = 30
            break
        case "3m":

            passkeyAppendInterval = 90
            break
        default:
            Logger.error('Invalid interval', {passkeyAppendInterval})

            passkeyAppendInterval = 0
            break
    }

    return passkeyAppendInterval
}

const showPasskeyAppend = passkeyAppendInterval => {
    let popUpShownAt = localStorage.getItem("passkeyAppendPopUpShownAt")

    if (popUpShownAt) {
        popUpShownAt = new Date(popUpShownAt)
        const now = new Date()
        const diff = now.getTime() - popUpShownAt.getTime()
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24))

        if (diffDays < passkeyAppendInterval) {
            return false
        } else {
            localStorage.setItem("passkeyAppendPopUpShownAt", now.toISOString())
        }
    } else {
        localStorage.setItem("passkeyAppendPopUpShownAt", new Date().toISOString())
    }

    return true
}

export default {
    state() {
        return {
            allowUserRegistration: null,
            passkeyAppendInterval: null,
            fallbackLanguage: null,
            autoDetectLanguage: null,
            showPasskeyAppend: null,
            userFullNameRequired: false,
            environment: 'prod',
        }
    },
    mutations: {
        setFromProjectConfig(state, config) {
            state.userFullNameRequired = config.userFullNameRequired
            state.allowUserRegistration = config.allowUserRegistration
            state.passkeyAppendInterval = config.passkeyAppendInterval
            state.fallbackLanguage = config.fallbackLanguage
            state.autoDetectLanguage = config.autoDetectLanguage
            state.debug = config.webComponentDebug
            state.environment = config.environment

            state.showPasskeyAppend = showPasskeyAppend(
                calculatePasskeyAppendInterval(config.passkeyAppendInterval)
            )
        },
    },
}