import Logger from "../helper/Logger";


export default {
    state() {
        return {
            errorMessage: '',
            errorDetailLink: '',
            errorLinks: '',
            errorRequestLink: '',
            errorRequestID: '',
            errorSide: '',
            errorType: '',
            forceShowError: '',
        }
    },
    mutations: {

    },
}