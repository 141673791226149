export default {
  // Register
  sign_up: "S'inscrire",
  name: "Nom",
  already_have_account: "Vous avez déjà un compte?",
  sign_in_instead: "Connectez-vous.",
  missing_username: "Merci d'entrer un nom d'utilisateur",
  missing_full_name: "Veuillez entrer un nom",

  // Login
  login: "Se connecter",
  back: "Retour",
  welcome_back: "Bienvenue! Veuillez entrer votre adresse e-mail",
  continue: "Continuer",
  email_address: "Adresse e-mail",
  dont_have_account: "Vous n'avez pas de compte?",

  email_magic_link: "Lien magique par e-mail",
  open_gmail: "Ouvrir dans Gmail",
  open_yahoo: "Ouvrir dans Yahoo",
  open_outlook: "Ouvrir dans Outlook",

  resend_email: "Renvoyer l'e-mail",
  resend_countdown: "Renvoyer l'e-mail ({{sec}} sec)",
  cancel: "Annuler",
  check_inbox: "Veuillez vérifier votre boîte de réception",
  other_login_options: "Autres options de connexion",
  login_passwordless: "Se connecter sans mot de passe via:",
  continue_with_biometric_method: "Continuer avec",
  password: 'Mot de passe',

  // Append Passkey
  activate: "Activer",
  login_faster: "Connectez-vous plus rapidement avec Touch ID, Face ID ou un code PIN",
  maybe_later: "Peut-être plus tard",
  tired_of_passwords: "Encore un mot de passe?",

  account_not_confirmed: 'Il semble que votre compte n\'ait pas encore été confirmé. Veuillez vérifier votre boîte de réception <span class="username">[username]</span>.',
  email_was_sent_to: 'Un e-mail a été envoyé à <span class="username">[username]</span>.',

  invalid_email_link: 'Lien invalide. Veuillez copier le lien depuis l\'e-mail.',
  processing: 'Traitement...',

  email_link_expired: 'Le lien est invalide ou a expiré.',
  passkey_cancelled: 'Connexion avec le code d\'accès impossible. Veuillez vous connecter avec le lien magique par e-mail envoyé à l\'adresse suivante <span class="username">[username]</span>.',
  passkey_deleted: 'Le passkey que vous avez choisi a été supprimé du serveur. Pour continuer à accéder à votre compte, utilisez un autre passkey ou cliquez sur le lien magique que nous venons d\'envoyer à <span class="username">[username]</span> pour vous connecter.',
  passkey_deleted_headline: 'Le passkey sélectionné a été supprimé',

  connectionTimeout: 'Votre connexion au serveur a été interrompue. Veuillez réessayer plus tard.',
  tooManyRequests: 'Vous avez fait trop de demandes. Veuillez réessayer plus tard.',
  showDetails: 'Afficher les détails',
  hideDetails: 'Masquer les détails',

  invalid_logintoken_link: 'Le jeton de connexion ne peut pas être lu depuis l\'adresse.',
  logintoken_link_expired: 'Le jeton de connexion est invalide ou a déjà été utilisé.',
}
