import HandleAxiosError from "../helper/HandleAxiosError";
import WCAxios from "./WCAxios";
import {shortCookie} from "../helper/CookieHelper";
import {setLongSession} from "../helper/LocalStorageHelper";

export default class UserService {

    constructor(errorHandler) {
        this._errorHandler = errorHandler
    }

    tokenFieldName = 'accessToken'
    userDataFieldName = 'userData'

    CreateSession(accessToken, userPayload) {
        localStorage.setItem(this.tokenFieldName, accessToken)
        localStorage.setItem(this.userDataFieldName, JSON.stringify(userPayload))
    }

    AuthMethods(username) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/authmethods', {username}), this._errorHandler).then(rsp => {
            return rsp.data.data
        })
    }

    ProjectConfig() {
        return HandleAxiosError(WCAxios.get().get('/v1/projects/projectConfig'), this._errorHandler).then(rsp => {
            return rsp.data.data
        })
    }

    LoginToken(token) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/logintoken', {token}), this._errorHandler).then(rsp => {
            shortCookie(rsp.data.data)
            setLongSession(rsp.data.data.longSession)

            return {sessionToken: rsp.data.data.sessionToken, redirectURL: rsp.data.data.redirectURL, authMethods: rsp.data.data.authMethods}
        })
    }

    DevicePasskey(loginIdentifier, loginIdentifierType) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/device/passkey', {
            loginIdentifier,
            loginIdentifierType
        }), this._errorHandler)
    }

}
