export default class Timeout {
    async timeout(ms) {
        return new Promise((resolve, reject) => {
            try {
                setTimeout(() => {
                    resolve()
                }, ms)
            } catch (e) {
                reject(e)
            }
        })
    }

    async interval(ms, callback) {
        return setInterval(() => {
            try {
                callback()
            } catch (e) {
                console.error(e)
            }
        }, ms)
    }

    clearInterval(id) {
        clearInterval(id)
    }

}