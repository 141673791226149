<template>

  <styled-btn
    dataAction="other_login_options"
    type="secondary"
    :fullWidth="true"
    @click="showOthers"
  >{{ i18n.$t('other_login_options') }}</styled-btn>

</template>
<script>
import StyledBtn from "../elements/StyledBtn.vue";
import store from "../store";
import {AuthMethods, FlowHandler} from "../routing";
import {useI18n} from "../i18n/i18nPlugin";
import {inject} from "vue";

export default {
  components: {StyledBtn},
  name: "expand-other-login-options",
  setup() {
    const i18n = useI18n();

    const showOthers = async (e) => {
      e.preventDefault()

      const errorHandler = inject('errorHandler')
      const flowHandler = new FlowHandler(store, errorHandler)
      const selected = await flowHandler.handle(store.state.user.loginMethods.filter(method => method !== 'webauthn'), false)

      store.commit('setCurrentLoginMethod', selected)
    }

    return {
      showOthers,
      i18n,
    }
  }
}

</script>