export default `const timeoutIds = {}

const assertNumber = (value) => {
    assertDefined(value)

    if (!value.toString().match(/^\\d+$/)) {
        throw new Error('Not number ' + value);
    }
}

const assertDefined = (value) => {
    if (value === undefined) {
        throw new Error('Undefined number');
    }
}

self.onmessage = e => {

    switch (e.data.event) {
        case 'timeout':

            assertDefined(e.data.payload?.id)
            assertNumber(e.data.payload?.ms)

            timeoutIds[e.data.payload.id] = setTimeout(() => {
                self.postMessage({
                    id: e.data.payload.id
                })
            }, e.data.payload.ms)

            break

        case 'resetInterval': {
            assertDefined(e.data.payload?.id)

            clearInterval(timeoutIds[e.data.payload.id])
            break
        }

        case 'interval':

            assertDefined(e.data.payload?.id)
            assertNumber(e.data.payload?.ms)

            timeoutIds[e.data.payload.id] = setInterval(() => {
                self.postMessage({
                    id: e.data.payload.id
                })
            }, e.data.payload.ms)

            break
    }
    
}
`