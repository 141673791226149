import {getCookieValue, shortCookie} from "../helper/CookieHelper";
import * as jose from "jose";
import WCAxios from "../services/WCAxios";
import User from "../models/User";
import {deleteLongSession} from "../helper/LocalStorageHelper";
import TimerWrapper from "./TimerWrapper";

export default class SessionService {

    #currentUser = null;
    error;
    #endpoint;
    #projectID;
    #autoRefreshTimeout = null;
    #autoRefreshStop = false;
    #stateCheckerInterval = null;
    #offlineInterval = null;
    #callbackFn = null
    #timerWrapper
    #JWKS

    constructor(projectID, endpoint = '') {
        this.#projectID = projectID
        if (endpoint === '') {
            endpoint = 'https://' + projectID + '.frontendapi.corbado.io'
        }

        this.#endpoint = endpoint
        this.#timerWrapper = new TimerWrapper()

        const callOptions = {
            cacheMaxAge: 600000,
            cooldownDuration: 30000,
            timeoutDuration: 5000,
        }
        if (this.#projectID !== '') {
            callOptions.headers = {
                'X-Corbado-ProjectID': this.#projectID
            }
        }

        this.#JWKS = jose.createRemoteJWKSet(new URL(this.#endpoint + "/.well-known/jwks"), callOptions)

        WCAxios.configure(wcInstance => {

            wcInstance.defaults.baseURL = endpoint
            wcInstance.defaults.timeout = 60 * 1000
            wcInstance.defaults.withCredentials = true

            if (projectID !== '') {
                wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = projectID
            }
            wcInstance.defaults.headers.common['X-Corbado-Client-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

            return wcInstance
        })

        document.addEventListener("visibilitychange", () => {
            this.#handleVisibilityChange()
        });

    }

    #handleVisibilityChange() {
        if (!window.hidden) {
            this.#performRefresh().catch(e => {
                if (e !== null) {
                    console.error(e)
                }
            })
        }
    }

    #parseJWK = async jwt => {
        const { payload, protectedHeader } = await jose.jwtVerify(jwt, this.#JWKS, {})

        return {protectedHeader, payload}
    }

    #performRefresh = () => {
        return WCAxios.get().put('/v1/sessions/refresh').then(rsp => {
            shortCookie(rsp.data)

            return rsp.data
        }).catch(err => {
            if (err.name !== 'AxiosError') {
                throw err
            }

            if (err.code === 'ERR_NETWORK') {

                this.error = 'Network error - please check cors settings'
                this.#backOnline()

                throw null
            }

            // If we're not logged in we get an 401
            if (err.response && err.response.status === 401) {
                this.#currentUser = null
                this.#callCallback(null)
                deleteLongSession()

                throw null
            }

            throw err
        })
    }

    #backOnline = () => {

        if (this.#offlineInterval !== null) {
            return
        }

        this.#offlineInterval = this.#timerWrapper.interval(10 * 10000).then(id => {
            this.#performRefresh().then(() => {
                this.#timerWrapper.clearInterval(id)
                this.#offlineInterval = null
            }).catch(e => {
                if (e !== null) {
                    console.error(e)
                }
            })
        })
    }

    #autoRefresh = () => {
        this.#performRefresh().then(rsp => {
            // This means we catch an error before
            // On success we receive a valid response
            if (rsp === null) {
                return
            }

            this.#keepSessionAlive()
        }).catch(e => {
            if (e !== null) {
                console.error(e)
            }
        })
    }

    #keepSessionAlive = () => {
        this.#parseJWK(getCookieValue('cbo_short_session')).then(rsp => {

            if (this.#autoRefreshStop) {
                this.#autoRefreshStop = false
                return
            }

            const expiresInSec = Math.floor(rsp.payload.exp - ((new Date()).getTime() / 1000))
            console.log('jwt expires in ' + expiresInSec + ' sec')

            this.#currentUser = new User(rsp.payload.sub, rsp.payload.orig, rsp.payload.name, rsp.payload.email ?? "", rsp.payload.phone_number ?? "")
            this.#callCallback(this.#currentUser)

            // Perform auto refresh directly in order to prevent issues with negative values with set timeout
            if (expiresInSec <= 10) {
                this.#autoRefresh()

                return
            }

            this.#autoRefreshTimeout = this.#timerWrapper.timeout(
                (expiresInSec - 10) * 1000
            ).then(() => {
                this.#autoRefresh()
            })
        })

    }

    #callCallback = (value) => {
        if (this.#callbackFn !== null) {
            this.#callbackFn(value)
        }
    }

    #sessionExists = () => {
        return getCookieValue('cbo_short_session') !== ''
    }

    refresh(callbackFn) {
        this.#callbackFn = callbackFn

        // We onl can register it one time
        if (this.#stateCheckerInterval !== null) {
            return
        }

        let triggeredInitialAlive = false
        if (this.#sessionExists()) {
            triggeredInitialAlive = true
            this.#keepSessionAlive()
        } else {
            this.#performRefresh().catch(e => {
                if (e !== null) {
                    console.error(e)
                }
            })
        }

        this.#stateCheckerInterval = this.#timerWrapper.interval(200, () => {
            const exists = this.#sessionExists()

            if (exists && this.#autoRefreshTimeout === null) {
                if (triggeredInitialAlive) {
                    triggeredInitialAlive = false
                    return
                }

                this.#keepSessionAlive()
            } else if (!exists && this.#autoRefreshTimeout !== null) {
                this.#autoRefreshStop = true
                this.#autoRefreshTimeout = null
                this.#callCallback(null)
            }

        })
    }

    async isAuthenticated() {
        return new Promise((resolve, reject) => {

            if (this.#sessionExists()) {
                this.#parseJWK(getCookieValue('cbo_short_session')).then(rsp => {
                    resolve(true);
                }).catch(err => {
                    if (err !== null) {
                        console.error('err', err)
                    }
                    resolve(false)
                })
            } else {
                this.#performRefresh().then(rsp => {
                    // This means we catch an error before
                    // On success we receive a valid response
                    resolve(rsp !== null)
                }).catch(err => {
                    if (err !== null) {
                        console.error(err)
                    }
                    resolve(false)
                })
            }
        })
    }

    get currentUser() {
        return this.#currentUser;
    }

    isAuthed() {
        return this.#currentUser !== null;
    }

    logout() {
        return WCAxios.get().delete('/v1/sessions/logout').then(rsp => {
            shortCookie(rsp.data)
        }).catch(err => {
            console.error(err)
        })
    }

}
