<template>

  <div>
    <error-handler>
      <div
        v-if="authed"
      >
        <slot
            name="authed"
        ></slot>
      </div>
      <div
        v-if="!authed"
      >
        <slot
            name="unauthed"
        ></slot>
      </div>
      <slot></slot>
    </error-handler>
  </div>

</template>

<script>
import {provide, ref} from "vue";
import validateProjectID from "./helper/ValidateProjectID";
import validateEndpoint from "./helper/ValidateEndpoint";
import ErrorHandler from "./ErrorHandler";
import IntegrationErrorClient from "./errors/IntegrationErrorClient";
import Layout from "./Layout";
import ErrorStateHandler from "./errors/ErrorStateHandler";
import SessionService from "./session/SessionService";

export default {
  name: "AuthProvider",
  components: {ErrorHandler, Layout},
  props: {
    endpoint: {
      default: '',
      type: String,
    },
    'project-id': {
      type: String,
    },
  },
  setup(props, context) {

    const errorHandler = ref(new ErrorStateHandler())
    provide('errorHandler', errorHandler)

    try {
      validateProjectID(props.projectId)
      if (props.endpoint !== '') {
        validateEndpoint(props.endpoint)
      }

      const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'

      const session = new SessionService(props.projectId, endpoint)
      const authed = ref(false)

      session.refresh(authState => {
        authed.value = authState !== null
      })

      return {
        authed
      }

    } catch (err) {
      errorHandler.value.error = {
        message: err.message,
        links: err.type === 'client' ? [err.link] : '',
        errorSide: err.type === 'client' ? 'client' : 'server',
        forceShowError: err.type === 'client' ? err.forceShowError : false,
      }

      return {
      }
    }

  },
}
</script>