<template>
  <div
    id="corbado-auth" class="container"
  >
    <error-handler style="margin: -2.5rem -2rem;">

      <template
          v-if="loaded"
      >
        <component
            v-if="conditional === 'yes'"
            :is="renderPage"
        >
          <template v-slot:usernameField><slot></slot></template>
          <template v-slot:signupFields><slot name="signupFields"></slot></template>
        </component>
        <component
            v-if="conditional !== 'yes'"
            :is="renderPage"
        ></component>
      </template>
    </error-handler>
  </div>
</template>

<script>
import {AuthMethods, Pages} from "./routing";
import Login from "./pages/Login.vue";
import PasswordAuthMethod from "./pages/Flow/Password/AuthMethod";
import PassKeysAuthMethod from "./pages/Flow/PassKeys/AuthMethod";
import EmailDirectAuthMethod from "./pages/Flow/EmailDirect/AuthMethod";
import EmailAuthMethod from "./pages/Flow/Email/AuthMethod";
import store from "./store";
import AppendPassKey from "./pages/AppendPassKey.vue";
import EmailLinkConfirm from "./pages/EmailLinkConfirm.vue";
import EmailLinkPending from "./pages/EmailLinkPending.vue";
import Register from "./pages/Register.vue";
import {computed, inject, provide, ref, useSlots} from "vue";
import InputField from "./elements/InputField";
import {detectLocale, provideI18n} from "./i18n/i18nPlugin";
import messagesEn from "./i18n/locales/en";
import messagesDe from "./i18n/locales/de";
import messagesFr from "./i18n/locales/fr";
import ErrorHandler from "./ErrorHandler";
import ConnectionTimeout from "./pages/ConnectionTimeout";
import TooManyRequests from "./pages/TooManyRequests";
import WCAxios from "./services/WCAxios";
import UserService from "./services/UserService";
import LoginToken from "./pages/LoginToken";
import IntegrationErrorClient from "./errors/IntegrationErrorClient";
import Layout from "./Layout";
import {ValidateOptionalProps, ValidateRequiredProps} from "./helper/ValidateProps";
import ErrorStateHandler from "./errors/ErrorStateHandler";
import PasskeyAppend from "./PasskeyAppend";

const createProjectConfigFromData = data => {

  return {
    autoDetectLanguage: data.autoDetectLanguage,
    fallbackLanguage: data.fallbackLanguage,
    allowUserRegistration: data.allowUserRegistration,
    passkeyAppendInterval: data.passkeyAppendInterval,
    userFullNameRequired: data.userFullNameRequired,
    webComponentDebug: data.webComponentDebug,
    environment: data.environment,
  }

}

export default {
  components: {
    PasskeyAppend,
    Layout,
    TooManyRequests,
    ConnectionTimeout,
    ErrorHandler, InputField, Register, EmailLinkPending, EmailLinkConfirm, AppendPassKey, Login, LoginToken},
  setup(props, context) {

    const errorHandler = ref(new ErrorStateHandler())
    provide('errorHandler', errorHandler)

    const cfg = ref({
      locale: 'en',
      messages: {
        en: messagesEn,
        de: messagesDe,
        fr: messagesFr,
      }
    })

    const i18n = provideI18n(cfg);
    const loaded = ref(false)
    const userSvc = new UserService(errorHandler)

    try {
      ValidateRequiredProps(props)
      store.commit(
          'setRequiredFromProps',
          props
      )

      WCAxios.configure(wcInstance => {

        wcInstance.defaults.baseURL = store.state.webComponent.endpoint
        wcInstance.defaults.timeout = 60 * 1000
        wcInstance.defaults.withCredentials = true
        wcInstance.defaults.headers.common['X-Corbado-Client-Timezone'] = store.state.webComponent.timeZone

        if (store.state.webComponent.projectID !== '') {
          wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = store.state.webComponent.projectID
        }

        return wcInstance
      })

      ValidateOptionalProps(props)
      store.commit(
          'setOptionalFromProps',
          props
      )

      userSvc.ProjectConfig().then(data => {
        store.commit('setFromProjectConfig',
            createProjectConfigFromData(data)
        )

        i18n.changeLocale(store.state.projectConfig.autoDetectLanguage === false ?
            store.state.projectConfig.fallbackLanguage :
            detectLocale(['de', 'en', 'fr'], store.state.projectConfig.fallbackLanguage)
        )
        loaded.value = true
      }).catch(() => {
        loaded.value = true
      })

    } catch (err) {

      errorHandler.value.error = {
        message: err.message,
        links: err.type === 'client' ? [err.link] : '',
        errorSide: err.type === 'client' ? 'client' : 'server',
        forceShowError: err.type === 'client' ? err.forceShowError : false,
      }

      return {
        renderPage: '',
        i18n,
        loaded,
      }
    }

    store.commit('setUsername',
        props.username !== '' ? props.username : localStorage.getItem('username') ?? ''
    )

    store.commit('setUserFullName',
      props.userFullName !== '' ? props.userFullName :  ''
    )

    localStorage.getItem('username')

    const userField = document.getElementById('corbado-username')

    const renderPage = computed(() => {

      switch (store.state.pages.currentPage ?? props.page) {

        case Pages.PAGE_LOGIN: {

          if (userField !== null) {
            userField.setAttribute('disabled', '');
          }

          switch (store.state.user.currentLoginMethod) {

            case AuthMethods.PASSWORD: {
              return PasswordAuthMethod
            }

            case AuthMethods.WEBAUTHN: {
              return PassKeysAuthMethod
            }

            case AuthMethods.EMAIL_DIRECT: {
              return EmailDirectAuthMethod
            }

            case AuthMethods.EMAIL: {
              return EmailAuthMethod
            }

          }

          if (userField !== null) {
            userField.removeAttribute('disabled');
          }

          return Login
        }

        case Pages.PAGE_REGISTER: {
          if (userField !== null) {
            userField.removeAttribute('disabled');
          }

          if (store.state.projectConfig.allowUserRegistration === false) {
            return Login
          }
          return Register
        }

        case Pages.PAGE_EMAIL_PENDING: {
          return EmailLinkPending
        }

        case Pages.PAGE_EMAIL_LINK_CONFIRM: {
          return EmailLinkConfirm
        }

        case Pages.PAGE_APPEND_PASSKEY: {
          return AppendPassKey
        }

        case Pages.PAGE_TIMEOUT: {
          return ConnectionTimeout
        }

        case Pages.PAGE_TOO_MANY_REQUESTS: {
          return TooManyRequests
        }

        case Pages.PAGE_LOGIN_TOKEN: {
          return LoginToken
        }

        default : {
          return "error"
        }

      }

    });

    store.commit('setCompanyImageUrl', props.companyimageurl)

    if (userField !== null && props.conditional === 'yes') {

      userField.value = store.state.user.username

      userField.addEventListener('focusin', () => {
        store.commit('setUserFieldFocus', true)
      })
      userField.addEventListener('focusout', () => {
        store.commit('setUserFieldFocus', false)
      })

    }

    return {
      renderPage,
      i18n,
      loaded,
    }

  },
  props: {
    endpoint: {
      default: '',
      type: String,
    },
    project_id: {
      default: '',
      type: String,
    },
    'project-id': {
      default: '',
      type: String,
    },
    conditional: {
      default: 'no',
      type: String,
    },
    companyimageurl: {
      default: '',
      type: String,
    },
    "company-image-url": {
      default: '',
      type: String,
    },
    page: {
      default: Pages.PAGE_LOGIN,
      type: String,
    },
    register_title: {
      default: null,
      type: String,
    },
    'register-title': {
      default: null,
      type: String,
    },
    register_btn: {
      default: null,
      type: String,
    },
    'register-btn': {
      default: null,
      type: String,
    },
    login_title: {
      default: null,
      type: String,
    },
    'login-title': {
      default: null,
      type: String,
    },
    login_btn: {
      default: null,
      type: String,
    },
    'login-btn': {
      default: null,
      type: String,
    },
    sub_title: {
      default: null,
      type: String,
    },
    'sub-title': {
      default: null,
      type: String,
    },
    'passkey-append-title': {
      default: null,
      type: String
    },
    'passkey-append-text': {
      default: null,
      type: String,
    },
    debug: {
      default: 'no',
      type: String,
    },
    username: {
      default: '',
      type: String,
    },
    userFullName: {
      default: '',
      type: String
    },
    mode: {
      default: 'normal',
      type: String,
    },
    'disable-input-animation': {
      default: 'no',
      type: String,
    },
  },
  beforeCreate() {
    store.dispatch('detectWebauthnSupported')
  },
};
</script>

<style>
#corbado-auth {
  max-width: 384px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow, none);
  margin-top: var(--margin-top, inherit);
}

.container {
  max-width: 30rem;
}

</style>
