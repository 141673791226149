<template>

  <styled-btn
      dataAction="continue_with_biometric_method"
      type="primary"
      :full-width="true"
      @click="handleLogin"
      :isLoading="loading"
  >{{ i18n.$t('continue_with_biometric_method') }} {{ biometricMethod }}</styled-btn>
  <error-field
      v-if="errorMessage.username !== undefined && errorMessage.username !== ''"
      :error-message="errorMessage.username"
  ></error-field>

</template>
<script>
import StyledBtn from "../elements/StyledBtn";
import {computed, inject, ref} from "vue";
import store from "../store";
import ErrorField from "../elements/ErrorField";
import WebauthnService from "../services/WebauthnService";
import {useI18n} from "../i18n/i18nPlugin";
import {Pages} from "../routing";
import EmailLinkService from "../services/EmailLinkService";

export default {
  components: {ErrorField, StyledBtn},
  name: "passkeys-login",
  setup() {
    const i18n = useI18n();

    const biometricMethod = computed(() => {
      return store.state.browser.biometricMethod
    })

    const errorHandler = inject('errorHandler')
    const webauthnSvc = new WebauthnService(errorHandler)
    const emailLinkSvc = new EmailLinkService(errorHandler)

    const loading = ref(false)
    const errorMessage = ref({})

    const handleLogin = e => {
      e.preventDefault()

      loading.value = true;
      return webauthnSvc.SignIn(store.state.user.username).then(redirectURL => {
        loading.value = false;
        // @ts-ignore
        window.location = redirectURL
      }).catch(err => {
        if (err === null) {
          return
        }

        loading.value = false;
        if (err.name === 'NotAllowedError') {
          emailLinkSvc.SignIn(store.state.user.username).then(emailLinkID => {
            store.commit('setEMailLinkIDPending', emailLinkID)
            store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
            store.commit('setContext', 'passkey_cancelled')
          }).catch(err => {
            if (err === null) {
              return
            }

            errorMessage.value = err
          })
        } else {
          errorMessage.value = err
        }
      })
    }

    return {
      biometricMethod,
      handleLogin,
      errorMessage,
      loading,
      i18n,
    }
  }
}
</script>