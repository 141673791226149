<template>

  <div>

    <div class="heading text-center">
      <img
          v-if="companyImageUrl !== ''"
          :src="companyImageUrl"
          height="40"
          style="margin-bottom: 20px; display: inline-block"
      />
      <h1>{{ pageTitle }}</h1>
    </div>

    <form @submit="signupHandler">
      <div class="input-group">
        <input-field
            v-if="showUserFullName"
            id="name"
            type="text"
            name="name"
            :label="i18n.$t('name')"
            autocomplete="name"
            v-model="name"
            :value="name"
            :errorMessage="errorMessage.name">
        </input-field>
        <input-field
            id="username"
            type="email"
            name="username"
            autocomplete="email"
            :label="i18n.$t('email_address')"
            v-model="username"
            :value="username"
            :errorMessage="errorMessage.username">
        </input-field>

        <slot name="signupFields"></slot>
      </div>

      <error-field
          v-if="errorMessage.global !== undefined"
          :errorMessage="errorMessage.global"></error-field>

      <styled-btn
          type="primary"
          dataAction="sign_up"
          :fullWidth="true"
          @click="signupHandler"
          :isLoading="signupLoading"
      >{{ ButtonText }}</styled-btn>

      <p class="text-small text-center">
        {{ i18n.$t('already_have_account') }}
        <span class="link" @click="switchPageLogin" data-action="switch_to_login">{{ i18n.$t('sign_in_instead') }}</span>
      </p>

    </form>
  </div>

</template>

<style>
.btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

@media screen and (min-width: 576px) {
  .btn-wrapper {
    flex-direction: row;
  }
}

</style>
<script>
import InputField from "../elements/InputField.vue";
import StyledBtn from "../elements/StyledBtn.vue";
import {computed, inject, ref} from "vue";
import ErrorField from "../elements/ErrorField.vue";
import store from "../store";
import {Pages} from "../routing";
import WebauthnService from "../services/WebauthnService"
import EmailLinkService from "../services/EmailLinkService"
import { useI18n } from '../i18n/i18nPlugin'
import Logger from "../helper/Logger";

export default {
  components: {ErrorField, StyledBtn, InputField},
  setup() {
    const i18n = useI18n();
    const errorHandler = inject('errorHandler')

    const signupLoading = ref(false)
    const errorMessage = ref({})
    const preFilledUsername = computed(() => {
      return store.state.user.username
    })
    const username = ref(preFilledUsername.value)
    const prefilledUserFullName = computed(()=> {
      return store.state.user.userFullName
    })
    const name = ref(prefilledUserFullName.value)
    const showUserFullName = computed(() => {
      return store.state.projectConfig.userFullNameRequired
    })

    const webauthnSvc = new WebauthnService(errorHandler)
    const emailLinkSvc = new EmailLinkService(errorHandler)

    const signupHandler = (e) => {
      e.preventDefault()

      signupLoading.value = true
      errorMessage.value = {};

      if (username.value.trim().length === 0) {
        errorMessage.value = Object.assign({}, errorMessage.value,  {username: i18n.$t('missing_username')});
      }

      if (name.value.trim().length === 0 && showUserFullName.value) {
        errorMessage.value = Object.assign({}, errorMessage.value, {name: i18n.$t('missing_full_name')});
      }

      let anyError = false

      Object.keys(store.state.fields.validationRules).forEach(key => {
        const validate = store.state.fields.validationRules[key]

        if (validate() === false) {
          anyError = true;
        }
      })

      if (Object.keys(errorMessage.value).length > 0 || anyError) {
        signupLoading.value = false
        return
      }

      store.commit('setUsername', username.value)

      if (store.state.browser.webauthnSupported) {
        Logger.debug('Initiate webauthn signup')

        webauthnSvc.SignUp(username.value, name.value).then(redirectURL => {
          Logger.debug('Performed webauthn signup', {redirectURL})
          signupLoading.value = false

          // @ts-ignore
          window.location = redirectURL
        }).catch(err => {
          if (err === null) {
            return
          }

          signupLoading.value = false
          if (err.name === 'NotAllowedError' || err.name === 'UnknownError') {
            Logger.debug('Webauthn signup failed', {err})

            emailLinkSvc.SignIn(store.state.user.username).then(emailLinkID => {
              Logger.debug('Sent email link', {username: store.state.user.username, emailLinkID})

              store.commit('setEMailLinkIDPending', emailLinkID)
              store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
              store.commit('setContext', 'authentication_method_pending')
            }).catch(err => {
              if (err !== null) {
                errorMessage.value = err
              }
            })
          } else {
              errorMessage.value = err
          }
        })
      } else {

        emailLinkSvc.SignUp(username.value, name.value).then(emailLinkID => {
          signupLoading.value = false

          Logger.debug('Send email link', {username: username.value, emailLinkID})
          store.commit('setEMailLinkIDPending', emailLinkID)
          store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
        }).catch(err => {
          signupLoading.value = false

          if (err !== null) {
            errorMessage.value = err
          }
        })
      }
    }

    const switchPageLogin = (e) => {
      e.preventDefault()

      store.commit(
          "switchPage",
          Pages.PAGE_LOGIN
      )
    }

    const companyImageUrl = store.state.pages.companyImageUrl

    return {
      signupHandler,
      signupLoading,
      errorMessage,
      switchPageLogin,
      username,
      name,
      companyImageUrl,
      i18n,
      showUserFullName,
    }

  },
  computed: {
    pageTitle() {
      return store.state.webComponent.registerTitle ?? this.i18n.$t('sign_up')
    },
    ButtonText() {
      return store.state.webComponent.registerBtn ?? this.i18n.$t('sign_up')
    },
  }
}
</script>