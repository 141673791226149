import IntegrationErrorClient from "../errors/IntegrationErrorClient";
import AttributeErrorFactory from "./AttributeErrorFactory";

export default function (endpoint) {
    if ((!endpoint.startsWith('https://') && !endpoint.startsWith('http://'))) {
        AttributeErrorFactory('endpoint', 'It must start with "https://" or "http://"', true)
    }

    if (endpoint.substring(endpoint.length - 1, endpoint.length) === '/') {
        AttributeErrorFactory('endpoint', 'It must not end with "/"', true)
    }
}