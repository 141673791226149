<template>

  <div>
    <div class="heading text-center">
      <img
          v-if="companyImageUrl !== null"
          :src="companyImageUrl"
          height="40"
          style="margin-bottom: 20px; display: inline-block"
      />

      <h1>{{ pageTitle }}</h1>
      <p v-if="subTitle != ''">{{ subTitle }}</p>
    </div>
    <form @submit="continueLoginHandler">
      <div class="input-group">

        <username-input-field
            v-if="performConditionalUI"
            :label="i18n.$t('email_address')"
            :error-message="errorMessage.username"
        >
          <slot name="usernameField"></slot>
        </username-input-field>
        <input-field
            v-if="!performConditionalUI"
            id="username"
            type="email"
            name="username"
            autocomplete="username webauthn"
            :label="i18n.$t('email_address')"
            :value="username"
            v-model="username"
            :error-message="errorMessage.username"
        >
        </input-field>

      </div>

      <styled-btn
          @click="continueLoginHandler"
          type="primary"
          dataAction="continue"
          :fullWidth="true"
          :isLoading="loading"
      >{{ ButtonText }}</styled-btn>
      <error-field
          v-if="errorMessage.global !== undefined"
          :errorMessage="errorMessage.global"></error-field>
      <p
          v-if="allowUserRegistration"
          class="text-small text-center">
          {{ i18n.$t('dont_have_account') }}
          <span class="link" @click="SignUp" data-action="switch_to_sign_up">{{ i18n.$t('sign_up') }}</span>
      </p>

    </form>
  </div>

</template>

<style>

button {
  margin: 1rem 0;
}
</style>
<script>
import InputField from "../elements/InputField.vue";
import StyledBtn from "../elements/StyledBtn.vue";
import {computed, inject, ref} from "vue";
import ErrorField from "../elements/ErrorField.vue";
import store from "../store";
import {AuthMethods, FlowHandler, Pages} from "../routing"
import InputFieldContainer from "../elements/InputFieldContainer";
import WebauthnService from "../services/WebauthnService";
import EmailLinkService from "../services/EmailLinkService";
import UsernameInputField from "../elements/UsernameInputField";
import { useI18n } from '../i18n/i18nPlugin'
import Logger from "../helper/Logger";
import ParseErrorMessage from "../helper/ParseErrorMessage";

export default {
  components: {UsernameInputField, InputFieldContainer, ErrorField, StyledBtn, InputField},
  setup(props, {slots}) {

    const i18n = useI18n();
    const userField = document.getElementById('corbado-username')
    const errorHandler = inject('errorHandler')
    const webauthnSvc = new WebauthnService(errorHandler)

    const loading = ref(false)
    const preFilledUsername = computed(() => {
      return store.state.user.username
    })
    const username = ref(preFilledUsername.value)

    const errorMessage = ref({})

    const allowUserRegistration = computed(() => {
      return store.state.projectConfig.allowUserRegistration
    })

    const emailLinkSvc = new EmailLinkService(errorHandler)

    const password = ref('')
    // When using older markup we set conditional ui no
    // When no given slot is not given
    // Other than that we have a slot
    const performConditionalUI = !!slots.usernameField

    const loginMethods = computed(() => {
      return store.state.user.loginMethods
    })
    const flowHandler = new FlowHandler(store, errorHandler)

    const continueLoginHandler = e => {
      e.preventDefault()

      webauthnSvc.AbortMediation()

      if (performConditionalUI) {
        store.commit('setUsername', userField.value)
        username.value = userField.value
      }

      errorMessage.value = {}

      if (username.value.trim().length === 0) {
        errorMessage.value = {username: i18n.$t('missing_username')}

        return
      }

      store.commit('setUsername', username.value)
      loading.value = true

      store.dispatch('fetchLoginMethods', {username: username.value, errorHandler}).then(async rsp => {
        const selectedMethod = await flowHandler.handle(rsp.selectedMethods)
        Logger.debug('Login flow selected ' + selectedMethod, {methods: rsp.selectedMethods})

        if (selectedMethod === AuthMethods.WEBAUTHN) {
          Logger.debug('Start webauthn signin')

          return webauthnSvc.SignIn(username.value).then(redirectURL => {
            Logger.debug('Finish webauthn signin', {redirectURL})

            loading.value = false;
            // @ts-ignore
            window.location = redirectURL
          }).catch(err => {
            if (err === null) {
              return
            }

            loading.value = false;

            if (err.name === 'SecurityError') {

              errorHandler.value.error = {
                message: 'Relying Party ID and Browser URL not matching. Please check the Corbado developer panel',
                errorLink: 'https://app.corbado.com/app/settings/general/urls',
              }

            } else if (err.name === 'NotAllowedError' || err?.response?.data?.error?.type === 'not_found') {
              Logger.debug('Webauthn failed')

              flowHandler.handle(rsp.selectedMethods, true, ['webauthn']).then(selectedMethod => {
                Logger.debug('Change method', {selectedMethod})
                store.commit('setCurrentLoginMethod', selectedMethod)
                store.commit('setContext', 'passkey_cancelled')

              }).catch(err => {
                errorMessage.value = ParseErrorMessage(err)
              })
            } else {
              errorMessage.value = err
            }
          })
        }

        store.commit('setCurrentLoginMethod', selectedMethod)
        loading.value = false;

        return rsp
      }).catch(err => {
        loading.value = false

        if (err === null) {
          return
        }

        errorMessage.value = err

      })
    }

    const SignUp = e => {
      e.preventDefault()

      if (performConditionalUI) {
        const userField = document.getElementById('corbado-username')
        store.commit('setUsername', userField.value)
      }

      webauthnSvc.AbortMediation()

      store.commit(
          "switchPage",
          Pages.PAGE_REGISTER
      )

    }

    const companyImageUrl = store.state.webComponent.companyImageURL

    if (performConditionalUI) {
      userField.onkeyup = e => {
        if (e.key === 'Enter') {
          continueLoginHandler(e)
        }
      }
    }

    return {
      loading,
      errorMessage,
      SignUp,
      continueLoginHandler,
      loginMethods,
      password,
      allowUserRegistration,
      username,
      companyImageUrl,
      performConditionalUI,
      i18n,
      emailLinkSvc,
      errorHandler,
    }
  },
  mounted() {
    if (this.performConditionalUI) {
      store.dispatch('processWebauthnMediation', {errorHandler: this.errorHandler}).catch(err => {
        if (err === null) {
          return
        }

        if (err?.response?.status === 400 || err.message === 'authentication_method_pending') {

          if (err.response.data.errorData.username) {
            store.commit('setUsername', err.response.data.errorData.username)
          }

          return this.emailLinkSvc.SignIn(store.state.user.username).then(emailLinkID => {
            store.commit('setEMailLinkIDPending', emailLinkID)
            store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
            store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
            store.commit('setContext',
                err.message === 'authentication_method_pending' ? 'authentication_method_pending' : 'passkey_deleted'
            )
          }).catch(err => {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.validation) {
              this.errorMessage.value = Object.assign({}, ParseErrorMessage(err.response.data.error.validation))
            } else {
              Logger.error('Failed to send email magic link', err)
            }
          })

        } else if (err !== 'User choose different login') {
          Logger.error('Conditional ui failed', err)
        }

      })
    }
  },
  computed: {
    pageTitle() {
      return store.state.webComponent.loginTitle ?? this.i18n.$t('login')
    },
    subTitle() {
      return store.state.webComponent.subTitle  ?? this.i18n.$t('welcome_back')
    },
    ButtonText() {
      return store.state.webComponent.loginBtn ?? this.i18n.$t('continue')
    },
  },
}
</script>