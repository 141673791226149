import ParseErrorMessage from "./ParseErrorMessage";
import store from "../store";
import {Pages} from "../routing";

export default function HandleAxiosError(promise, errorHandler) {

    return promise.catch(err => {

        if (err.name === 'AxiosError') {

            let errorLink = ''
            if (err.response?.headers?.get('x-corbado-help') !== '' && err.response?.headers?.get('x-corbado-help') !== undefined) {
                errorLink = err.response.headers.get('x-corbado-help')
            }

            // 400 error means we have a validation error that are mapped to a field
            // and should get displayed to customers as its user input
            // 422 error means that we have a general invalid value from client, but it is no user input.
            // For example the length of user agent seams invalid, ip address is not correct etc.
            if (err.code === 'ERR_NETWORK') {
                if (err.response === undefined) {
                    errorHandler.value.error = {
                        message: "Network error - it seams like you're offline or have CORS issues",
                        detailLink: errorLink,
                        requestLink: "https://www.corbado.com/cors-error",
                        requestID: err.response?.data?.requestData?.requestID,
                        errorType: err.response?.data?.error?.type,
                    }
                } else {
                    errorHandler.value.error = {
                        message: "Network error - please check your CORS settings",
                        detailLink: errorLink,
                        requestLink: "https://www.corbado.com/cors-error",
                        requestID: err.response?.data?.requestData?.requestID,
                        errorType: err.response?.data?.error?.type,
                    }
                }

                throw null
            } else if (err.code === 'ECONNABORTED') {
                store.commit('switchPage', Pages.PAGE_TIMEOUT)

                throw null
            } else if (err.response.data.error.type === 'invalid_origin') {
                errorHandler.value.error ={
                    message: "Network error - please check cors settings",
                    detailLink: errorLink,
                    errorType: err.response.data.error.type,
                    requestID: err.response.data.requestData.requestID,
                    requestLink: "https://www.corbado.com/cors-error",
                    links: err.response.data.error.links,
                }

                throw null
            } else if (err.response.data.error.type === 'client_error') {
                errorHandler.value.error = {
                    message: "It seems like you have set up Corbado webhooks to make Corbado exchange user information with your backend. Unfortunately, your backend is not reachable or the Corbado CLI is not set up correctly (e.g. when you're testing locally). Please remove webhooks URL, webhooks username and webhooks password in the developer panel if you don't have existing users in your backend that you need to connect to Corbado (e.g. you're building an entirely new application).",
                    detailLink: errorLink,
                    customError: "webhookError",
                    errorType: err.response.data.error.type,
                    requestID: err.response.data.requestData.requestID,
                    requestLink: err.response.data.requestData.link,
                    links: err.response.data.error.links,
                }

                throw null
            } else if (err.response.status === 422) {
                let errorMessage = ""
                err.response.data.error.validation?.forEach(e => {
                    errorMessage += e.field + ": " + e.message + "; "
                })

                errorHandler.value.error = {
                    message:  err.response.data.error.details,
                    detailLink: errorLink,
                    errorType: err.response.data.error.type,
                    requestID: err.response.data.requestData.requestID,
                    requestLink: err.response.data.requestData.link,
                    links: err.response.data.error.links,
                }

                throw null
            } else if (err.response.status === 429) {
                store.commit('switchPage', Pages.PAGE_TOO_MANY_REQUESTS)

                throw null
            } else if (err.response.data.error.type === 'validation_error') {
                throw Object.assign({}, ParseErrorMessage(err.response.data.error.validation))
            } else if (err.response.status === 500) {
                errorHandler.value.error = {
                    message: "An server error has occurred. Check the developer console.",
                    detailLink: errorLink,
                    errorType: err.response.data.error.type,
                    requestID: err.response.data.requestData.requestID,
                    requestLink: err.response.data.requestData.link,
                    links: err.response.data.error.links,
                }

                throw null
            } else if (errorLink !== '') {
                errorHandler.value.error = {
                    requestID: '',
                    detailLink: errorLink,
                    message: 'Please follow the link in order to better understand the error',
                    requestLink: err.response.data.requestData.link,
                }

                throw null
            }

        }

        throw err
    })

}