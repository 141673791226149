import axios from "axios";
import HandleAxiosError from "../helper/HandleAxiosError";
import WCAxios from "./WCAxios";
import {shortCookie} from "../helper/CookieHelper";
import {setLongSession} from "../helper/LocalStorageHelper";

export default class EmailLinkService {

    constructor(errorHandler) {
        this._errorHandler = errorHandler
    }

    SignIn(username) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/emaillinks/login/start', {username}), this._errorHandler).then(rsp => {
            shortCookie(rsp.data.data)
            return rsp.data.data.emailLinkID
        })
    }

    Confirm(emailLinkID, token) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/emaillinks/confirm', {emailLinkID, token}), this._errorHandler).then(rsp => {
            shortCookie(rsp.data.data)
            setLongSession(rsp.data.data.longSession)
            return {redirectURL: rsp.data.data.redirectURL, sessionToken: rsp.data.data.sessionToken, authMethods: rsp.data.data.authMethods}
        })
    }

    Status(emailLinkID) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/emaillinks/status', {emailLinkID}), this._errorHandler).then(rsp => {
            shortCookie(rsp.data.data)
            setLongSession(rsp.data.data.longSession)
            return {redirectURL: rsp.data.data.redirectURL, sessionToken: rsp.data.data.sessionToken}
        })
    }

    SignUp(username, name) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/emaillinks/register/start', {
            email: username,
            username: name,
        }), this._errorHandler).then(rsp => {
            // @ts-ignore
            return rsp.data.data.emailLinkID
        })
    }
}