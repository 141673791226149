import {Pages} from "../routing";
import validateProjectID from "./ValidateProjectID";
import validateEndpoint from "./ValidateEndpoint";
import AttributeErrorFactory from "./AttributeErrorFactory";

const required = [
    // ProjectID
    props => {
        let projectID = props.projectId

        if (projectID === '') {
            projectID = props['project_id']
        }

        if (projectID === '') {
            return
        }

        validateProjectID(projectID)
    },

    // Endpoint
    props => {
        if (props.endpoint === '') {
            return
        }

        validateEndpoint(props.endpoint)
    },

    props => {
        if (props.endpoint === '' && props.projectId === '' && props.project_id === '') {
            AttributeErrorFactory(
                'projectID',
                'Attribute "endpoint" or "projectID" not given. Provide at least one',
                true,
            )
        }
    },
]

const optional = [

    // Conditional
    props => {
        if (!['yes', 'no'].includes(props.conditional)) {
            AttributeErrorFactory(
                'conditional',
                'Should be yes or no.',
            )
        }
    },

    // Companyimageurl
    props => {
        if (props.companyimageurl.length > 0 && !props.companyimageurl.startsWith('/') && !props.companyimageurl.startsWith('http://') && !props.companyimageurl.startsWith('https://')) {

            AttributeErrorFactory(
                'companyimageurl',
                'Should start with / or http:// or https://',
            )
        }


        if (props.companyImageUrl.length > 0 && !props.companyImageUrl.startsWith('/') && !props.companyImageUrl.startsWith('http://') && !props.companyImageUrl.startsWith('https://')) {
            AttributeErrorFactory(
                'companyimageurl',
                'Should be yes or no.',
            )
        }
    },

    // Debug
    props => {
        if (!['yes', 'no'].includes(props.debug)) {
            AttributeErrorFactory(
                'debug',
                'Should be yes or no.',
            )
        }
    },

    // DisableInputAnimation
    props => {
        if (!['yes', 'no'].includes(props.disableInputAnimation)) {
            AttributeErrorFactory(
                'disable-input-animation',
                'Should be yes or no.',
            )
        }
    },

    // Debug
    props => {
        if (!['normal', 'preview'].includes(props.mode)) {
            throw new Error('Invalid mode, should be normal or preview.')
        }
    },

    // Page
    props => {
        if (!
            [
                Pages.PAGE_TIMEOUT,
                Pages.PAGE_LOGIN,
                Pages.PAGE_EMAIL_PENDING,
                Pages.PAGE_REGISTER,
                Pages.PAGE_EMAIL_PENDING,
                Pages.PAGE_EMAIL_LINK_CONFIRM,
                Pages.PAGE_APPEND_PASSKEY,
                Pages.PAGE_TOO_MANY_REQUESTS,
                Pages.PAGE_LOGIN_TOKEN,
            ].includes(props.page)) {
            AttributeErrorFactory(
                    'page',
                    'Should be login, register, timeout, email-pending, email-link-confirm, append-passkey or too-many-requests.',
                )
        }
    },
]



export function ValidateRequiredProps(props) {
    required.forEach(v => {
        v(props)
    })
}

export function ValidateOptionalProps(props) {
    optional.forEach(v => {
        v(props)
    })
}
