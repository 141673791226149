<template>
  <div class="dialog" v-if="showDialog">
    <div class="dialog-content">
      <div class="dialog-header">Delete passkey</div>
      <div class="dialog-body">Are you sure you want to delete the passkey?</div>
      <div class="dialog-footer">
        <styled-btn
            class="btn-danger"
            dataAction="delete_passkey"
            full-width
            @click="confirmDeletePasskey"
        >Yes, delete
        </styled-btn>
        <styled-btn
            class="btn-outline"
            full-width
            @click="showDialog = false"
        >Cancel
        </styled-btn>
      </div>
    </div>
  </div>

  <Layout>
    <error-handler>
      <div v-if="currentAuthState || true"
      >
        <div v-if="passkeys.length">
          <div v-for="p in passkeys" style="margin-bottom: 20px; display: flex;">
            <div class="v-col container"
                 style="width: 100%; padding-top: 0px; padding-bottom: 0px; min-height: 0">
              <div class="row" style="flex-wrap: nowrap; margin-right: 0px; margin-left:0px">
                <div class="passkey-icon-container col-sm-1">
                  <div v-if="hasAaguidIcon(p.aaguid)">
                    <div v-html="getAaguidIcon(p.aaguid)"></div>
                  </div>
                  <DefaultPasskeyIcon v-else />
                </div>
                <div class="col-sm-10">
                  <div class="row passkey-attribute">
                    <div class="col-sm-12" style="display: flex;align-items: center;">
                      <h3 style="color: #fff">{{ getAaguidName(p.aaguid) }}</h3>
                      <span v-if="p.backupState" class="passkey-type-badge">Synced</span>
                    </div>
                  </div>
                  <div class="row passkey-attribute">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <b>Credential ID:</b> {{ p.credentialHash }}
                    </div>
                  </div>
                  <div class="row passkey-attribute">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <b>Created:</b> {{ p.created }} with {{ getParsedUA(p.userAgent) }}
                    </div>
                  </div>
                  <div class="row passkey-attribute">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <b>Last used:</b> {{ p.lastUsed }}
                    </div>
                  </div>
                  <div class="row passkey-attribute">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <b>Status:</b> {{ p.status }}
                    </div>
                  </div>
                </div>
                <div class="icon-container col-sm-1" @click="deletePassKey(p)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          fill="white"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="passkeys.length == 0">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12"><p>No passkeys found</p></div>
            </div>
          </div>
        </div>
      </div>
    </error-handler>
  </Layout>

</template>

<script>
import {provide, ref} from "vue";
import Layout from "./Layout";
import StyledBtn from "./elements/StyledBtn.vue";
import validateEndpoint from "./helper/ValidateEndpoint";
import validateProjectID from "./helper/ValidateProjectID";
import MeService from "./services/MeService";
import WCAxios from "./services/WCAxios";
import WebauthnService from "./services/WebauthnService";
import store from "./store";
import {UAParser} from 'ua-parser-js';
import ErrorHandler from "./ErrorHandler";
import ErrorStateHandler from "./errors/ErrorStateHandler";
import SessionService from "./session/SessionService";
import {aaguidMapping} from "./aaguidMapping";
import DefaultPasskeyIcon from "./elements/DefaultPasskeyIcon.vue";

export default {
  components: {ErrorHandler, Layout, StyledBtn, DefaultPasskeyIcon},
  props: {
    'project-id': String,
    'endpoint': {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const currentAuthState = ref(false)

    const passkeys = ref([])
    const paging = ref({})
    const showDialog = ref(false)
    const selectedPasskey = ref(null)
    const errorHandler = ref(new ErrorStateHandler())
    provide('errorHandler', errorHandler)
    const meSvc = new MeService(errorHandler)
    const webauthnSvc = new WebauthnService(errorHandler);


    try {
      validateProjectID(props.projectId)

      const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'
      validateEndpoint(endpoint)

      WCAxios.configure(wcInstance => {
        wcInstance.defaults.baseURL = endpoint
        wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = props.projectId
        return wcInstance
      })

      const session = new SessionService(props.projectId, props.endpoint)
      session.refresh(user => {
        currentAuthState.value = user !== null

        if (user === null) {
          return
        }

        meSvc.ListPassKeys(store.state.user.sessionToken).then(rsp => {
          paging.value = rsp.paging
          passkeys.value = rsp.passkeys
        })
      })

    } catch (err) {
      errorHandler.value.error = {
        message: err.message,
        links: err.type === 'client' ? [err.link] : '',
        errorSide: err.type === 'client' ? 'client' : 'server',
        forceShowError: err.type === 'client' ? err.forceShowError : false,
      }

      return {}
    }

    const getAaguidName = (aaguid) => {
      return aaguidMapping[aaguid]?.name || "Passkey"
    }

    const hasAaguidIcon = (aaguid) => {
      const iconData = aaguidMapping[aaguid];
      return !!(iconData && iconData.icon_dark);
    }

// Update the getAaguidIcon method to only return the image string
    const getAaguidIcon = (aaguid) => {
      const iconData = aaguidMapping[aaguid];
      if (iconData && iconData.icon_dark) {
        let svgIcon = iconData.icon_dark;
        return `<img src="${svgIcon}" alt="${iconData.name}" style="width: 50px; height: 50px; margin: 20px 0 20px 0;"/>`;
      }
      return null;
    }


    const getParsedUA = (UAString) => {
      const {browser, os} = UAParser(UAString)
      return browser.name + " on " + os.name
    }

    const deletePassKey = (passkey) => {
      selectedPasskey.value = passkey
      showDialog.value = true
    }

    const confirmDeletePasskey = () => {
      console.log(`Deleting passkey ${selectedPasskey.value.id}`)
      webauthnSvc.DeletePasskey(selectedPasskey.value.id).then(rsp => {
        passkeys.value = passkeys.value.filter(p => p.id !== selectedPasskey.value.id)
      }).catch(err => {
        console.log(err)
      });
      showDialog.value = false
    }

    return {
      deletePassKey,
      confirmDeletePasskey,
      currentAuthState,
      passkeys,
      showDialog,
      getAaguidName,
      getAaguidIcon,
      hasAaguidIcon,
      getParsedUA
    }
  }
}
</script>

<style scoped>
.container {
  box-sizing: border-box;
  background-color: #2A334C;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.row > div {
  color: white;
}

.row {
  margin-bottom: 10px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  padding: 20px;
  background: #2A334C;
  border-radius: 10px;
}

.dialog-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.dialog-body {
  margin-bottom: 20px;
  color: white;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.btn {
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.btn-danger {
  background: red;
  margin-right: 10px;
  color: white;
}

.btn-outline {
  border: 1px solid white;
  background: transparent;
  color: white;
}

.btn-danger:hover {
  background: #ff4d4d;
}

.btn-outline:hover {
  background: rgba(255, 255, 255, 0.3);
}

.passkey-type-badge {
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 12px;
  margin-left: 5px;
  height: 15px;
}

.passkey-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passkey-attribute {
  margin-left: 0px;
  margin-right: 0px;
}

</style>
