<template>

  <div
    v-if="showError"
    class="error-handler"
  >
    <div v-if="environment === 'dev' || forceShowError">
      <h3
        class="headline"
      >
        Integration error ({{ errorSide }} side)
      </h3>

      <div
        class="row"
      >
        <div
          class="col-xs-3"
        >
          Message:
        </div>
        <div
          :class="showTruncatedData.message ? ['col-xs-9', 'truncate'] : ['col-xs-9']"
          @click="showAll('message')"
        >
          <a v-if="errorDetailLink !== ''" :href="errorDetailLink" target="_blank" class="link">
            {{ errorStore }}
          </a>
          <span v-else>
            {{ errorStore }}
          </span>
        </div>
      </div>

      <div
          class="row"
          v-if="errorType !== ''"
      >
        <div
            class="col-xs-3"
        >
          Type:
        </div>
        <div
            class="col-xs-9"
        >
          {{ errorType }}
        </div>
      </div>

      <div
        class="row"
      >
        <div
          class="col-xs-3"
          v-if="errorLinks.length > 0"
        >
          Links:
        </div>
        <div
          class="col-xs-9 truncate"
          :class="showTruncatedData.link ? ['col-xs-9', 'truncate'] : ['col-xs-9']"
          @click="showAll('link')"
        >
          <template
            v-for="l in errorLinks"
          >
            <a
              class="link"
              :href="l"
              target="_blank"
            >
              {{ l }}
            </a><br />
          </template>
        </div>
      </div>

      <div
        class="row"
        v-if="errorRequestID !== ''"
      >
        <div
          class="col-xs-3"
        >
          RequestID:
        </div>
        <div
          class="col-xs-9"
        >
          <a
              class="link"
              :href="errorRequestLink"
              target="_blank"
          >
            {{ errorRequestID }}
          </a> <svg-icon @click="copyRequestID" style="vertical-align: text-top; cursor: pointer" type="mdi" size="14" :path="mdiContentCopy"></svg-icon>
        </div>
      </div>

      <span id="details">
        See browser console for more details
      </span>

    </div>

    <p id="info" v-if="environment === 'dev' || forceShowError" style="display: flex; align-items: center">
      <svg-icon type="mdi" size="28" style="vertical-align: text-top; margin-right: 5px" :path="mdiInformationOutline"></svg-icon>
      <span>Error only shown because project is in development mode!</span>
    </p>

    <div v-if="environment === 'prod' && !forceShowError" class="error-message">
      <svg-icon type="mdi" style="vertical-align: text-top" size="48" :path="mdiAlertCircleOutline"></svg-icon>
      <br /><br />
      An error occurred.<br />
      Please try again later.<br />
      <br />
      <template v-if="errorRequestID !== ''">RequestID: {{ errorRequestID }}</template>
    </div>

  </div>

  <slot v-else></slot>

</template>

<style scoped>
a, .link {
  text-decoration: underline;
  font-weight: normal;
  color: #ff0000;
  cursor: pointer;
}

.col-xs-3 {
  padding-right: 3px;
}

.col-xs-9 {
  padding-left: 3px;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-handler {
  background-color: #fae4ab;
  border: 2px solid #ff0000;
  font-size: 14px;
  color: #ff0000;
  min-height: 245px;
  position: relative;
  padding-bottom: 50px;
  max-width: 30rem;
}

.headline {
  background-color: #f00;
  padding: 0.5rem 1.3rem;
  color: #fff;
  font-size: 16px;
  margin: 0 0 0.8rem;
}

.row div:first-child {
  text-align: right;
  color: #936c3e;
  font-weight: bold;
}

.row {
  margin-bottom: 0.5rem;
}

.row div {
  color: #ff0000;
}

#details {
  display: block;
  color: #ff0000;
  margin: 1rem auto;
  border: 2px solid #ff0000;
  width: 250px;
  font-weight: bold;
  padding: 10px 15px;
}

#info {
  color: #936c3e;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
}

.error-message {
  font-size: 18px;
  color: #ff0000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}


</style>

<script>
import {computed, inject, onErrorCaptured, ref} from "vue";
import store from "./store";
import StyledBtn from "./elements/StyledBtn";
import {provideI18nIfNotGiven} from "./i18n/i18nPlugin";
import Logger from "./helper/Logger";
import WebhookError from "./components/WebhookError";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiContentCopy, mdiInformationOutline, mdiAlertCircleOutline } from '@mdi/js';
import messagesEn from "./i18n/locales/en";
import messagesDe from "./i18n/locales/de";
import messagesFr from "./i18n/locales/fr";

/**
 * This error handler make use of provided ErrorStateHandler which got provided by each web component instance.
 * Based on the given data it renders based on APP ENV information about the occurred error.
 *
 * This means: if you wanna use the error handler you have to provide an instance of ErrorStateHandler in the keyword errorHandler during setup of your entrypoint of WC
 */
export default {
  name: 'ErrorHandler',
  components: {WebhookError, StyledBtn, SvgIcon},
  setup() {

    const errorHandler = inject('errorHandler')

    const cfg = ref({
      locale: 'en',
      messages: {
        en: messagesEn,
        de: messagesDe,
        fr: messagesFr,
      }
    })

    const i18n = provideI18nIfNotGiven(cfg);

    const errorStore = computed(() => {
      return errorHandler.value.errorMessage
    })
    const errorLinks = computed(() => {
      return errorHandler.value.errorLinks
    })
    const errorRequestLink = computed(() => {
      return errorHandler.value.errorRequestLink
    })
    const errorRequestID = computed(() => {
      return errorHandler.value.errorRequestID
    })
    const errorSide = computed(() => {
      return errorHandler.value.errorSide
    })
    const errorType = computed(() => {
      return errorHandler.value.errorType
    })

    const forceShowError = computed(() => {
      console.log(errorHandler.value.forceShowError)
      return errorHandler.value.forceShowError
    })

    const errorDetailLink = computed(() => {
      return errorHandler.value.errorDetailLink
    })

    const errorCaptured = ref(false)
    const showError = computed(() => {
      return errorHandler.value.errorMessage !== '' || errorCaptured.value
    })

    const showTruncatedData = ref({
      message: true,
      link: true,
    })

    // Added onErrorCaptured lifecycle hook
    onErrorCaptured((e) => {
      Logger.error('Fetched error', e)
      errorCaptured.value = true

      return false;
    })

    const environment = computed(() => {
      return store.state.projectConfig.environment
    })

    const copyRequestID = () => {
      navigator.clipboard.writeText(errorRequestID.value)
    }

    const showAll = name => {
      showTruncatedData.value[name] = false
    }

    return {
      showError,
      errorStore,
      errorRequestID,
      store,
      i18n,
      errorLinks,
      errorRequestLink,
      errorSide,
      errorType,
      environment,
      mdiContentCopy,
      mdiInformationOutline,
      mdiAlertCircleOutline,
      copyRequestID,
      showAll,
      showTruncatedData,
      forceShowError,
      errorDetailLink,
    }
  }

};
</script>