export default {
    state() {
        return {
            validationRules: {},
        }
    },
    mutations: {
        addField(state, {name, validate}) {
            state.validationRules[name] = validate
        },
    },
}