// i18nPlugin.js
import {provide, inject} from "vue";

const createI18n = config => ({
    changeLocale: value => {
        if (config.value.locale === value) {
            return
        }

        config.value.locale = value
    },
    $t(key) {
        return config.value.messages[config.value.locale][key]
    }
});

const i18nSymbol = Symbol();

export function detectLocale(availables, fallback) {
    const language = navigator.language.split('-')[0]

    if (availables.includes(language)) {
        return language
    }

    return fallback
}

export function provideI18n(i18nConfig) {
    const i18n = createI18n(i18nConfig);
    provide(i18nSymbol, i18n);

    return i18n
}

export function useI18n() {
    const i18n = inject(i18nSymbol);
    if (!i18n) throw new Error("No i18n provided!!!");

    return i18n;
}

export function provideI18nIfNotGiven(i18nConfig) {
    let i18n = inject(i18nSymbol);
    if (!i18n) {
        i18n = provideI18n(i18nConfig)
    }

    return i18n;
}