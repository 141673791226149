import axios from "axios";
import {getLongSession} from "../helper/LocalStorageHelper";
import store from "../store";

const WSAxios = new function () {

    let instance = axios.create({
        headers: {
            'X-Corbado-WC-Version': process.env.npm_package_version,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
        params: {
            t: new Date().getTime()
        }
    });

    this.configure = (fnCallback) => {
        instance = fnCallback(instance)
    }

    this.get = () => {
        const token = getLongSession()

        instance.defaults.headers.common['X-Corbado-Mode'] = store.state.webComponent.mode

        if (token !== '') {
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }

        return instance
    }

}

export default WSAxios